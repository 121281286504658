import {post, get} from 'ys-admin'


/**
 * 安责险险任务列表
 * @param {Object} params
 * @returns {Object}
 */
export const checkList = function (params) {
  return get('/check/List', params)
};
/**
 * 安责险险任务列表
 * @param {Object} params
 * @returns {Object}
 */
export const checkCategoryList = function (params) {
  return get('/check/category/List', params)
};
/**
 *  获取安责险扣分明细列表
 * @param {Object} params
 * @returns {Object}
 */
export const getCategoryList = function (params) {
  return get('/check/category/List', params)
};
/**
 * 查询专项任务详情
 * @param {Object} params
 * @returns {Object}
 */
export const editCategoryFile = function (params) {
  return post('/check/category/EditFile', params)
};
/**
 * 安责险险任务列表
 * @param {Object} params
 * @returns {Object}
 */
export const policyInfo = function (params) {
  return get('/policy/Info', params)
};

/**
 * 查询安责险任务详情
 * @param {Object} params
 * @returns {Object}
 */
export const checkInfo = function (params) {
  return get('/check/GetInfo', params)
};
/**
 *  安责险任务选择检查大项
 * @param {Object} params
 * @returns {Object}
 */
export const checkProjectList = function (params) {
  return get('/check/SelectDict', params)
};
/**
 *  获取安责险任务检查内容
 * @param {Object} params
 * @returns {Object}
 */
export const checkDictGetInfo = function (params) {
  return get('/check/dict/GetInfo', params)
};


/**
 *  获取安责险扣分明细列表
 * @param {Object} params
 * @returns {Object}
 */
export const getDictItemInfo = function (params) {
  return get('/check/dict/detail/List', params)
};

/**
 *  确认安责险任务
 * @param {Object} params
 * @returns {Object}
 */
export const checkConfirm = function (params) {
  return post('/check/Confirm', params)
};

/**
 *  打卡
 * @param {Object} params
 * @returns {Object}
 */
export const checkSignIn = function (params) {
  return post('/check/SignIn', params)
};

/**
 *  编辑检查资料
 * @param {Object} params
 * @returns {Object}
 */
export const checkDocEdit = function (params) {
  return post('/check/doc/Edit', params)
};

/**
 *  安责险专家列表
 * @param {Object} params
 * @returns {Object}
 */
export const checkExpertList = function (params) {
  return get('/check/expert/List', params)
};

/**
 * 安责险任务分配专家
 * @param {Object} params
 * @returns {Object}
 */
export const checkSelectExpert = function (params) {
  return post('/check/SelectExpert', params)
};